<template>
  <b-container class="phone-settings-top-container">
    <b-row>
      <b-col>
        <virtual-phone-masthead
          title="Settings"
          :show-save-and-cancel-buttons="true"
          :mobile-view="mobileView"
          :submission-in-progress="submissionInProgress"
          @loaded="loadedPhoneMasthead"
          @changed="resetAfterChange"
          @submit="savePhoneSettings"
          @cancel="resetAfterChange"
        />
      </b-col>
    </b-row>
    <ct-centered-spinner v-if="!loaded" />
    <div v-else>
      <b-row>
        <b-col cols="12" lg="8" class="d-flex flex-column gap-3">
          <b-card
            class="phone-settings-card"
            header-class="phone-setting-card-header"
            body-class="phone-settings-card-body"
          >
            <template #header>
              <h4 class="mt-0 mb-0 phone-settings-header-text">Line</h4>
            </template>

            <b-card-body>
              <b-row class="mb-0">
                <b-col cols="12" md="6">
                  <b-form-group>
                    <label class="phone-settings-label">Phone Name</label>
                    <b-form-input
                      v-model="phoneName"
                      class="phone-settings-input"
                      :state="validPhoneName"
                      @change="setUserInputChangePhoneName"
                    />
                    <b-form-invalid-feedback id="input-live-feedback">
                      "The name must be 3–50 characters long, start and end with a letter or number, and can only include letters, numbers, spaces, hyphens, underscores, periods, or apostrophes."
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group>
                    <b-row no-gutters class="justify-content-start align-items-center mb-1">
                      <b-col class="mr-2" cols="auto">
                        <label class="mb-0 phone-settings-label">Call Forwarding</label>
                      </b-col>
                      <b-col class="mr-2" cols="auto">
                        <!-- Tooltip -->
                        <b-button v-b-tooltip.click title="Call forwarding redirects calls to the number you specify. Voicemail for these calls will be managed by the voicemail system of the forwarded number." variant="link" class="p-0">
                          <b-icon icon="question-circle-fill" variant="dark" />
                        </b-button>
                      </b-col>
                      <b-col cols="auto">
                        <b-form-checkbox v-model="callForwardingEnabled" class="align-checkbox-middle" name="call-forwarding-checkbox" size="lg" switch />
                      </b-col>
                    </b-row>
                    <vue-phone-number-input
                      v-model="forwardToNumber"
                      class="form-control p-0 phone-settings-call-forwarding-number-input"
                      :only-countries="['US']"
                      default-country-code="US"
                      :required="true"
                      :clearable="true"
                      :disabled="!callForwardingEnabled"
                      size="lg"
                      :no-example="true"
                      @update="validateNumberOnEvent"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" md="6">
                  <b-form-group>
                    <b-row no-gutters class="justify-content-start align-items-center mb-1">
                      <!-- Label Column -->
                      <b-col class="mr-2 text-nowrap" cols="auto">
                        <label class="mb-0 phone-settings-label">Attachment Retention Period</label>
                      </b-col>

                      <!-- Tooltip Column -->
                      <b-col cols="auto">
                        <b-button
                          v-b-tooltip.click
                          title="Attachment retention period is the time period that mms attachments are available."
                          variant="link"
                          class="p-0"
                        >
                          <b-icon icon="question-circle-fill" variant="dark" />
                        </b-button>
                      </b-col>
                    </b-row>

                    <!-- Dropdown -->
                    <b-form-select
                      v-model="messageAttachmentRetentionPeriod"
                      :options="messageAttachmentRetentionOptions"
                    />
                  </b-form-group>
                </b-col>
                <b-col>
                  <label class="phone-settings-label">Blocked Numbers</label>
                  <b-list-group v-if="(blockedNumbers.length > 0) && mobileView" class="blocked-numbers-list-group">
                    <div v-for="number in blockedNumbers" :key="number">
                      <div>
                        <virtual-phone-blocked-number-mobile-view :number="number" />
                      </div>
                    </div>
                  </b-list-group>
                  <b-row v-else-if="(blockedNumbers.length > 0) && !mobileView" class="mt-3">
                    <b-col>
                      <p class="show-blocked-numbers-text" @click="openBlockedNumbersModal()">See all blocked numbers</p>
                    </b-col>
                  </b-row>
                  <div v-else>
                    <p>No blocked numbers found.</p>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>

          <b-card
            class="phone-settings-card"
            header-class="phone-setting-card-header"
            body-class="phone-settings-card-body"
          >
            <template #header>
              <h4 class="mt-0 mb-0 phone-settings-header-text">Caller ID</h4>
            </template>
            <b-card-body>
              <b-row>
                <b-col>
                  <b-form-group>
                    <label class="phone-settings-label">Name</label>
                    <b-form-input
                      v-model="callerIdName"
                      :state="validCallerIdName"
                      @blur="saveCallerIdName"
                      @change="setUserUpdateCallerId"
                    />
                    <b-form-invalid-feedback id="input-live-feedback">
                      "Caller Id Name can be up 15 characters long, start and end with a letter or number, and can only include letters, numbers, spaces, hyphens, underscores, periods, or apostrophes."
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>

          <!-- Voicemail Card -->
          <b-card
            class="phone-settings-card"
            header-class="phone-setting-card-header"
            body-class="phone-settings-card-body"
          >
            <template #header>
              <h4 class="mt-0 mb-0 phone-settings-header-text">Voicemail</h4>
            </template>
            <b-card-body>
              <b-row>
                <b-col cols="12" md="6">
                  <b-form-group>
                    <label class="phone-settings-label">Greeting Type</label>
                    <b-form-select v-model="greetingType" :options="greetingTypeOptions" />
                  </b-form-group>
                  <b-button v-if="greetingType == 'custom'" class="w-100 mb-2 confirm-button confirm-button-light" @click="showRecorder">Manage Custom Greeting</b-button>
                </b-col>

                <b-col cols="12" md="6">
                  <b-form-group>
                    <b-row no-gutters class="justify-content-start align-items-center mb-1">
                      <!-- Label Column -->
                      <b-col class="mr-2 text-nowrap" cols="auto">
                        <label class="mb-0 phone-settings-label">Voicemail Retention Period</label>
                      </b-col>

                      <!-- Tooltip Column -->
                      <b-col cols="auto">
                        <b-button
                          v-b-tooltip.click
                          title="Setting a shorter retention period will delete voicemails older than the newly selected period, regardless of their prior retention period."
                          variant="link"
                          class="p-0"
                        >
                          <b-icon icon="question-circle-fill" variant="dark" />
                        </b-button>
                      </b-col>
                    </b-row>
                    <b-form-select
                      ref="voicemailSelect"
                      :value="voicemailRetentionPeriod"
                      :options="voicemailRetentionPeriodOptions"
                      @change="toggleVoicemailRetentionConfirmation"
                    />
                    <div v-if="voicemailRetentionChangeInProgress" class="voicemail-retention-warning mt-4">
                      <b-row>
                        <b-col cols="2" align-self="center">
                          <b-icon class="h3" icon="exclamation-triangle" />
                        </b-col>
                        <b-col>
                          <p class="voicemail-retention-warning-text">Are you sure that you want to proceed? This action may delete voicemails older than the selected period.</p>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col md="2" />
                        <b-col>
                          <b-button class="cancel-button" @click="cancelVoicemailRetentionChange">
                            Cancel
                          </b-button>
                        </b-col>
                        <b-col>
                          <b-button class="confirm-button-light confirm-button" @click="confirmVoicemailRetentionChange">
                            Confirm
                          </b-button>
                        </b-col>
                      </b-row>
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-col>

        <!-- Right Column: Go Mobile Card -->
        <b-col :class="{ 'mt-3': mobileView }" cols="12" lg="4">
          <virtual-phone-mobile-settings-card :mobile-view="mobileView" />
        </b-col>
      </b-row>
    </div>
    <virtual-phone-blocked-numbers-modal :blocked-numbers="blockedNumbers" />
    <virtual-phone-voicemail-recorder-modal
      ref="virtual-phone-voicemail-recorder-modal"
      :custom-voicemail-url="customVoicemailUrl"
      :upload-url="uploadUrl"
      @success="successfulVoicemailGreetingUpload"
      @failure="failedVoicemailGreetingUpload"
    />
  </b-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import VirtualPhoneMasthead from '@/components/VirtualPhones/VirtualPhoneMasthead'
import VirtualPhoneBlockedNumberMobileView
  from '@/components/VirtualPhones/VirtualPhoneBlockedNumberMobileView.vue'
import VirtualPhoneBlockedNumbersModal
  from '@/components/VirtualPhones/VirtualPhoneBlockedNumbersModal.vue'
import VirtualPhoneVoicemailRecorderModal from "@/components/VirtualPhones/VirtualPhoneVoicemailRecorderModal"
import { CALL_FORWARD_TYPES } from '@/common/modules/constants'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import VuePhoneNumberInput from 'vue-phone-number-input'
import { formatPhoneToCountryCodeStyle } from '@/common/modules/formatters'
import CtCenteredSpinner from '@/components/shared/CtCenteredSpinner.vue'
import VirtualPhoneMobileSettingsCard
  from '@/components/VirtualPhones/VirtualPhoneMobileSettingsCard.vue'

export default {
  name: "VirtualPhoneSettings",
  components: {
    VirtualPhoneBlockedNumbersModal,
    VirtualPhoneBlockedNumberMobileView,
    VirtualPhoneMasthead,
    VirtualPhoneVoicemailRecorderModal,
    VuePhoneNumberInput,
    CtCenteredSpinner,
    VirtualPhoneMobileSettingsCard,
  },
  data() {
    return {
      phoneName: "",
      callerIdName: "",
      callForwardingEnabled: false,
      forwardToNumber: "",
      formattedForwardToNumber: "",
      messageAttachmentRetentionPeriod: "",
      voicemailRetentionPeriod: "",
      greetingType: "",
      voicemailRetentionPeriodOptions: [
        { text: '2 Weeks', value: 14 },
        { text: '6 Weeks', value: 60 },
        { text: '6 Months', value: 182 },
      ],
      messageAttachmentRetentionOptions: [
        { text: '1 week', value: 7 },
        { text: '2 weeks', value: 14 },
        { text: '4 weeks', value: 28 },
      ],
      greetingTypeOptions: [
        { text: "Default", value: "generic" },
        { text: "Beep", value: "beep" },
        { text: "Custom" , value: "custom" },
      ],
      showModal: false,
      mobileView: false,
      voicemailRetentionChangeInProgress: false,
      proposedVoicemailRetentionPeriod: "",
      validNumber: "",
      submissionInProgress: false,
      loaded: false,
      userUpdatePhoneName: false,
      userUpdateCallerId: false,
    }
  },
  computed: {
    ...mapGetters('virtualPhones', ['selectedPhone', 'customVoicemailUrl', 'voicemailLifespanInDays', 'blockedNumbers']),
    uploadUrl() {
      return `client/virtual_phones/${this.selectedPhone.id}/upload-voicemail`
    },
    validPhoneName() {
      const validPhoneRegex = /^[a-zA-Z0-9][a-zA-Z0-9 _.'-]{1,48}[a-zA-Z0-9]$/
      if (this.userUpdatePhoneName) {
        return validPhoneRegex.test(this.phoneName)
      }

      return null
    },
    validCallerIdName() {
      const validCallerIdRegex = /^[a-zA-Z0-9][a-zA-Z0-9 _.'-]{0,13}[a-zA-Z0-9]$/
      if (this.userUpdateCallerId) {
        return validCallerIdRegex.test(this.callerIdName)
      }

      return null
    },
  },
  mounted() {
    this.checkViewport()
    window.addEventListener("resize", this.checkViewport)
    this.populateFormData()
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkViewport)
  },
  methods: {
    ...mapActions('virtualPhones', ['setCustomVoicemailSettings', 'loadVirtualPhones', 'getBlockedNumbers', 'setPhoneSettings', 'updateVoicemailSettings', 'setCallerIdSettings']),
    formatPhoneToCountryCodeStyle: formatPhoneToCountryCodeStyle,

    async loadedPhoneMasthead(){
      await this.loadVirtualPhones()
      await this.setCustomVoicemailSettings()
      await this.getBlockedNumbers()
      this.loaded = true
    },
    async resetAfterChange(){
      this.loaded = false
      await this.loadVirtualPhones()
      await this.setCustomVoicemailSettings()
      await this.getBlockedNumbers()
      this.populateFormData()
      this.loaded = true
    },
    checkViewport() {
      this.mobileView = window.innerWidth <= 768
    },
    openBlockedNumbersModal() {
      this.$bvModal.show("blocked-numbers-modal")
    },
    showRecorder(){
      this.$refs['virtual-phone-voicemail-recorder-modal'].show()
    },
    async successfulVoicemailGreetingUpload(){
      await this.saveVoicemailSettings()
      this.$refs['virtual-phone-voicemail-recorder-modal'].hide()
    },
    failedVoicemailGreetingUpload(){
      this.$bvToast.toast('Voicemail Greeting failed to Upload', {
        title: 'Error',
        autoHideDelay: 2000,
      })
      this.$refs['virtual-phone-voicemail-recorder-modal'].hide()
    },
    toggleVoicemailRetentionConfirmation(newValue) {
      this.proposedVoicemailRetentionPeriod = newValue
      this.voicemailRetentionChangeInProgress = true
    },
    confirmVoicemailRetentionChange() {
      this.voicemailRetentionPeriod = this.proposedVoicemailRetentionPeriod
      this.proposedVoicemailRetentionPeriod = ""
      this.voicemailRetentionChangeInProgress = false
      this.$refs.voicemailSelect.$el.value = this.voicemailRetentionPeriod
    },
    cancelVoicemailRetentionChange() {
      this.proposedVoicemailRetentionPeriod = ""
      this.voicemailRetentionChangeInProgress = false
      this.$refs.voicemailSelect.$el.value = this.voicemailRetentionPeriod
    },
    populateFormData() {
      const selectedPhone = this.selectedPhone
      this.phoneName = selectedPhone.name
      this.messageAttachmentRetentionPeriod = selectedPhone.media_lifespan_in_days
      this.callForwardingEnabled = (selectedPhone.forward_type === CALL_FORWARD_TYPES.NUMBER_ONLY)
      this.greetingType = selectedPhone.greeting_type
      this.voicemailRetentionPeriod = selectedPhone.voicemail_lifespan_in_days
      this.forwardToNumber = selectedPhone.forward_to_number
      this.callerIdName = selectedPhone.caller_id_name
    },
    validateNumberOnEvent(event) {
      this.validNumber = event.isValid
    },
    forwardNumberMatchesServiceNumber() {
      return this.formattedForwardToNumber === this.selectedPhone.number
    },
    forwardingDestination() {
      return this.forwardToNumber !== null ? formatPhoneToCountryCodeStyle(this.forwardToNumber) : ""
    },
    forwardToNumberInvalid() {
      return !this.validNumber
    },
    validateCallForwardingNumber() {
      if (this.callForwardingEnabled) {
        this.formattedForwardToNumber = this.forwardingDestination()

        if(this.forwardNumberMatchesServiceNumber()) {
          this.showErrorToast('Forward To Number cannot be the same as the service number')
          return false
        }

        if(this.forwardToNumberInvalid()) {
          this.showErrorToast('Call forwarding number is invalid.')
          return false
        }
      }

      return true
    },
    validatePhoneName() {
      if (!this.validPhoneName) {
        this.showErrorToast('Please enter a valid phone name.')
        return false
      }
      return this.validPhoneName
    },
    showErrorToast(message) {
      this.$bvToast.toast(message, {
        title: 'Error',
        variant: 'danger',
        solid: true,
      })
      return
    },
    async savePhoneSettings() {
      this.submissionInProgress = true
      const callForwardingNumberValid = this.validateCallForwardingNumber()
      const phoneNameValid = this.validatePhoneName()

      if (callForwardingNumberValid && phoneNameValid) {
        await this.submitPhoneSettings()
      }

      this.submissionInProgress = false
    },
    async submitPhoneSettings() {
      const selectedPhoneSettings = {
        name: this.phoneName || this.selectedPhone.name || '',
        forward_type: this.callForwardingEnabled ? CALL_FORWARD_TYPES.NUMBER_ONLY : CALL_FORWARD_TYPES.BROWSER_ONLY,
        forward_to_number:  this.callForwardingEnabled ? this.formattedForwardToNumber : null,
        media_lifespan_in_days: this.messageAttachmentRetentionPeriod,
      }

      try {
        const savePhoneSettingsActions = [this.setPhoneSettings({ phoneSettings: selectedPhoneSettings, vm: this }), this.saveVoicemailSettings()]
        await Promise.all(savePhoneSettingsActions)

        this.$bvToast.toast('Settings have been saved.', {
          title: 'Success',
          variant: 'success',
          autoHideDelay: 2000,
        })
      } catch {
        this.$bvToast.toast('We are having troubles at the moment. Please try again at another time.', {
          title: 'Error',
          variant: 'danger',
          solid: true,
          autoHideDelay: 2000,
        })
      }
    },
    async saveVoicemailSettings() {
      const selectedVoicemailSettings = {
        retentionPeriod: this.voicemailRetentionPeriod,
        greetingType: this.greetingType,
      }

      await this.updateVoicemailSettings( { updatedParams: selectedVoicemailSettings, vm: this })
    },
    async saveCallerIdName() {
      if (this.validCallerIdName) {
        await this.setCallerIdSettings({ callerIdSettings: { callerIdName: this.callerIdName }, vm: this })
      }
    },
    setUserInputChangePhoneName() {
      this.userUpdatePhoneName = true
    },
    setUserUpdateCallerId() {
      this.userUpdateCallerId = true
    },
  },
}
</script>

<style lang="scss">
  .gap-3 {
    gap: 1rem;
  }

  .phone-settings-card {
    border-radius: 0.344rem;
  }

  .phone-setting-card-header {
    background-color: #FFFFFF;
  }

  .phone-settings-card-body {
    padding: 0;
  }

  .phone-settings-header-text {
    font-size: 20px;
    font-weight: 400;
  }

  .phone-settings-label {
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.25rem;
  }

  .blocked-numbers-list-group {
    height: 7.5rem;
    overflow: scroll;
  }

  .show-blocked-numbers-text {
    color: #2E3798;
    font-size: 0.875rem;
    text-decoration: underline;
  }

  .phone-settings-button {
    border-radius: 0.2343rem;
    font-size: 0.787rem;
  }

  .confirm-button-light {
    background-color: #FFFFFF;
    color: #2E3798;
    border: 0.063rem solid #2E3798;
  }

  .confirm-button {
    font-size: 1rem;
    font-weight: 600;
    padding: .5rem 1rem;
  }

  .cancel-button {
    background-color: #FFFFFF;
    color: #4E4E52;
    border: 0.063rem solid #CECED2;
  }

  .go-mobile-icon {
    color: #009FBF;
  }

  .go-mobile-text {
    font-size: 1.75rem;
    font-weight: 700;
  }
  .go-mobile-info-text {
    font-size: 0.875rem;
    font-weight: 400;
  }

  .no-focus-outline:focus {
    outline: none;
    box-shadow: none;
  }

  .username-text {
    font-size: 0.875rem;
  }

  .download-link {
    background-color: #17a2b8;
    color: #FFFFFF;
    font-size: 0.875rem;
    font-weight: 400;
    padding: .5rem 1rem;
  }

  .voicemail-retention-warning-text {
    font-size: 0.875rem;
    font-weight: 400;
    color: #4E4E52;
  }

  .phone-settings-call-forwarding-number-input {
    border: 0 !important;
  }

  :deep .country-selector__input {
    height: 3.75rem !important;
  }

  :deep .input-tel__input {
    height: 3.75rem !important;
  }

  :deep .country-selector__country-flag {
    top: 2.063rem !important;
  }

  .align-checkbox-middle {
    margin-top: -.15rem;
  }
</style>
