var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-card",
    {
      staticClass: "phone-settings-card",
      attrs: {
        "header-class": "phone-setting-card-header",
        "body-class": "phone-settings-card-body",
      },
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function () {
            return [
              _c(
                "h4",
                { staticClass: "mt-0 mb-0 phone-settings-header-text" },
                [_vm._v("Mobile App Access")]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "b-card-body",
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                {
                  staticClass: "d-flex align-items-center",
                  attrs: { cols: "2" },
                },
                [
                  _c("b-icon", {
                    staticClass: "go-mobile-icon h2",
                    attrs: { size: "lg", icon: "patch-check-fill" },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                {
                  staticClass:
                    "d-flex align-items-center justify-content-start",
                },
                [
                  _c("h2", { staticClass: "m-0 go-mobile-text" }, [
                    _vm._v("Go Mobile!"),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "mt-2" },
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-row",
                    { staticClass: "align-items-center flex-wrap" },
                    [
                      _c(
                        "b-col",
                        {
                          staticClass: "username-col",
                          attrs: { cols: "auto" },
                        },
                        [
                          _c(
                            "p",
                            { staticClass: "mb-0 d-inline username-text" },
                            [_vm._v("Username:")]
                          ),
                          !_vm.editMode
                            ? _c(
                                "p",
                                {
                                  staticClass:
                                    "mb-0 d-inline username-text ml-1",
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.currentUsername) +
                                      "\n            "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      ),
                      !_vm.editMode
                        ? _c(
                            "b-col",
                            {
                              staticClass: "username-col",
                              attrs: { cols: "auto" },
                            },
                            [
                              _c("b-icon-pencil", {
                                staticClass: "ml-1",
                                on: {
                                  click: function ($event) {
                                    return _vm.enableEdit()
                                  },
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm.editMode
                    ? _c(
                        "b-row",
                        { staticClass: "mt-2" },
                        [
                          _c(
                            "b-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("b-form-input", {
                                staticClass: "mb-2",
                                model: {
                                  value: _vm.currentUsername,
                                  callback: function ($$v) {
                                    _vm.currentUsername = $$v
                                  },
                                  expression: "currentUsername",
                                },
                              }),
                              _c(
                                "div",
                                { staticClass: "d-flex justify-content-start" },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass:
                                        "mr-2 cancel-button phone-settings-button",
                                      attrs: { variant: "secondary" },
                                      on: { click: _vm.cancelEdit },
                                    },
                                    [
                                      _vm._v(
                                        "\n                Cancel\n              "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "b-button",
                                    {
                                      staticClass:
                                        "confirm-button-light phone-settings-button",
                                      on: { click: _vm.updateUsername },
                                    },
                                    [
                                      _vm.usernameSubmissionInProgress
                                        ? _c("fa-icon", {
                                            attrs: {
                                              icon: "spinner",
                                              spin: "",
                                            },
                                          })
                                        : _c("div", [
                                            _vm._v(
                                              "\n                  Save\n                "
                                            ),
                                          ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c("b-col", [
                _c("p", { staticClass: "go-mobile-info-text" }, [
                  _vm._v(
                    "\n          Take Phone Service everywhere you go, with the Corporate Phone mobile app.\n          To get started, scan the QR code, download the app, and log in with your email address\n          and existing client password.\n        "
                  ),
                ]),
                _c("p", { staticClass: "go-mobile-info-text" }, [
                  _vm._v(
                    "\n          Questions? Reach out to our team anytime.\n        "
                  ),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "justify-content-center my-3" },
            [
              _c(
                "b-button-group",
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "no-focus-outline",
                      attrs: {
                        variant:
                          _vm.selectedPhoneType === "iphone" ? "dark" : "light",
                      },
                      on: { click: _vm.togglePhoneType },
                    },
                    [_vm._v("\n          iPhone\n        ")]
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "no-focus-outline",
                      attrs: {
                        variant:
                          _vm.selectedPhoneType === "android"
                            ? "dark"
                            : "light",
                      },
                      on: { click: _vm.togglePhoneType },
                    },
                    [_vm._v("\n          Android\n        ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                {
                  staticClass: "d-flex justify-content-center",
                  attrs: { "align-self": "center" },
                },
                [
                  _vm.mobileView
                    ? _c(
                        "b-link",
                        { attrs: { href: _vm.linkByPhoneType() } },
                        [
                          _c("b-img", {
                            attrs: { src: `/images/${_vm.storeIcon()}` },
                          }),
                        ],
                        1
                      )
                    : _c("vue-qrcode", {
                        attrs: {
                          value: _vm.linkByPhoneType(),
                          options: { width: 150 },
                        },
                      }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }