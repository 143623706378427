<template>
  <b-card
    class="phone-settings-card"
    header-class="phone-setting-card-header"
    body-class="phone-settings-card-body"
  >
    <template #header>
      <h4 class="mt-0 mb-0 phone-settings-header-text">Mobile App Access</h4>
    </template>
    <b-card-body>
      <b-row>
        <b-col class="d-flex align-items-center" cols="2">
          <b-icon size="lg" class="go-mobile-icon h2" icon="patch-check-fill" />
        </b-col>
        <b-col class="d-flex align-items-center justify-content-start">
          <h2 class="m-0 go-mobile-text">Go Mobile!</h2>
        </b-col>
      </b-row>

      <b-row class="mt-2">
        <b-col>
          <b-row class="align-items-center flex-wrap">
            <b-col cols="auto" class="username-col">
              <p class="mb-0 d-inline username-text">Username:</p>
              <p v-if="!editMode" class="mb-0 d-inline username-text ml-1">
                {{ currentUsername }}
              </p>
            </b-col>
            <b-col v-if="!editMode" cols="auto" class="username-col">
              <b-icon-pencil class="ml-1" @click="enableEdit()" />
            </b-col>
          </b-row>
          <b-row v-if="editMode" class="mt-2">
            <b-col cols="12">
              <b-form-input
                v-model="currentUsername"
                class="mb-2"
              />
              <div class="d-flex justify-content-start">
                <b-button
                  variant="secondary"
                  class="mr-2 cancel-button phone-settings-button"
                  @click="cancelEdit"
                >
                  Cancel
                </b-button>
                <b-button
                  class="confirm-button-light phone-settings-button"
                  @click="updateUsername"
                >
                  <fa-icon v-if="usernameSubmissionInProgress" icon="spinner" spin />
                  <div v-else>
                    Save
                  </div>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <p class="go-mobile-info-text">
            Take Phone Service everywhere you go, with the Corporate Phone mobile app.
            To get started, scan the QR code, download the app, and log in with your email address
            and existing client password.
          </p>
          <p class="go-mobile-info-text">
            Questions? Reach out to our team anytime.
          </p>
        </b-col>
      </b-row>
      <b-row class="justify-content-center my-3">
        <b-button-group>
          <b-button class="no-focus-outline" :variant="selectedPhoneType === 'iphone' ? 'dark' : 'light'" @click="togglePhoneType">
            iPhone
          </b-button>
          <b-button class="no-focus-outline" :variant="selectedPhoneType === 'android' ? 'dark' : 'light'" @click="togglePhoneType">
            Android
          </b-button>
        </b-button-group>
      </b-row>
      <b-row>
        <b-col align-self="center" class="d-flex justify-content-center">
          <b-link v-if="mobileView" :href="linkByPhoneType()">
            <b-img :src="`/images/${storeIcon()}`" />
          </b-link>
          <vue-qrcode v-else :value="linkByPhoneType()" :options="{ width: 150 }" />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>


<script>
import VueQrcode from '@chenfengyuan/vue-qrcode'
import { mapActions, mapGetters } from 'vuex'
  export default {
    name: "VirtualPhoneMobileSettingsCard",
    components: { VueQrcode },
    props: {
      mobileView: Boolean,
    },
    data() {
      return {
        selectedPhoneType: "iphone",
        androidPhoneTokenLink: 'https://play.google.com/store/apps/details?id=com.corporatetools.phoneservice',
        iOSPhoneTokenLink: 'https://apps.apple.com/us/app/corporate-phone/id1593053186',
        currentUsername: "",
        editMode: false,
        usernameSubmissionInProgress: false,
      }
    },
    mounted() {
      this.populateFormData()
    },
    methods: {
      ...mapActions('account', [
        'updatePersonUsername',
        'loadAccount',
      ]),
      ...mapGetters("account", ["username", "account"]),
      ...mapGetters("virtualPhones", ["usernameRegex"]),
      linkByPhoneType() {
        return this.selectedPhoneType === "iphone" ? this.iOSPhoneTokenLink : this.androidPhoneTokenLink
      },
      storeIcon() {
        return this.selectedPhoneType === "iphone" ? "iosStore.png" : "googleStore.png"
      },
      populateFormData() {
        this.currentUsername = this.username()
      },
      togglePhoneType() {
        this.selectedPhoneType = this.selectedPhoneType === "iphone" ? "android" : "iphone"
      },
      enableEdit() {
        this.editMode = true
      },
      cancelEdit() {
        this.editMode = false
        this.populateFormData()
      },
      async updateUsername() {
        if (!this.usernameRegex().test(this.currentUsername)) {
          this.makeToast('danger', 'Invalid Username', 'Must be 6-32 characters long and can only include letters, numbers, underscores, and hyphens.')
          return
        }

        try {
          this.usernameSubmissionInProgress = true
          await this.updatePersonUsername({
            username: this.username,
            newUsername: this.currentUsername,
            personId: this.account.id,
          })
          this.usernameSubmissionInProgress = false
          this.editMode = false
          this.makeToast('primary', 'Success', 'Username Updated.')
        } catch {
          this.usernameSubmissionInProgress = false
          this.editMode = false
          this.makeToast('danger', 'Failure', 'The username could not be updated.')
        }
      },
      makeToast(variant = null, title, body) {
        this.$bvToast.toast(body, {
          title: title,
          variant: variant,
          solid: true,
        })
      },
    },
  }
</script>

<style>
/* When the container is too narrow, force each column to occupy the full width */
@media (max-width: 600px) {
  .username-text-container > .username-col {
    /* Force each column to stack */
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 0.5rem; /* optional spacing between rows */
  }
}
</style>

