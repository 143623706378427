var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    { staticClass: "phone-settings-top-container" },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [
              _c("virtual-phone-masthead", {
                attrs: {
                  title: "Settings",
                  "show-save-and-cancel-buttons": true,
                  "mobile-view": _vm.mobileView,
                  "submission-in-progress": _vm.submissionInProgress,
                },
                on: {
                  loaded: _vm.loadedPhoneMasthead,
                  changed: _vm.resetAfterChange,
                  submit: _vm.savePhoneSettings,
                  cancel: _vm.resetAfterChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      !_vm.loaded
        ? _c("ct-centered-spinner")
        : _c(
            "div",
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    {
                      staticClass: "d-flex flex-column gap-3",
                      attrs: { cols: "12", lg: "8" },
                    },
                    [
                      _c(
                        "b-card",
                        {
                          staticClass: "phone-settings-card",
                          attrs: {
                            "header-class": "phone-setting-card-header",
                            "body-class": "phone-settings-card-body",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "header",
                              fn: function () {
                                return [
                                  _c(
                                    "h4",
                                    {
                                      staticClass:
                                        "mt-0 mb-0 phone-settings-header-text",
                                    },
                                    [_vm._v("Line")]
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ]),
                        },
                        [
                          _c(
                            "b-card-body",
                            [
                              _c(
                                "b-row",
                                { staticClass: "mb-0" },
                                [
                                  _c(
                                    "b-col",
                                    { attrs: { cols: "12", md: "6" } },
                                    [
                                      _c(
                                        "b-form-group",
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass:
                                                "phone-settings-label",
                                            },
                                            [_vm._v("Phone Name")]
                                          ),
                                          _c("b-form-input", {
                                            staticClass: "phone-settings-input",
                                            attrs: {
                                              state: _vm.validPhoneName,
                                            },
                                            on: {
                                              change:
                                                _vm.setUserInputChangePhoneName,
                                            },
                                            model: {
                                              value: _vm.phoneName,
                                              callback: function ($$v) {
                                                _vm.phoneName = $$v
                                              },
                                              expression: "phoneName",
                                            },
                                          }),
                                          _c(
                                            "b-form-invalid-feedback",
                                            {
                                              attrs: {
                                                id: "input-live-feedback",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                '\n                    "The name must be 3–50 characters long, start and end with a letter or number, and can only include letters, numbers, spaces, hyphens, underscores, periods, or apostrophes."\n                  '
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    [
                                      _c(
                                        "b-form-group",
                                        [
                                          _c(
                                            "b-row",
                                            {
                                              staticClass:
                                                "justify-content-start align-items-center mb-1",
                                              attrs: { "no-gutters": "" },
                                            },
                                            [
                                              _c(
                                                "b-col",
                                                {
                                                  staticClass: "mr-2",
                                                  attrs: { cols: "auto" },
                                                },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "mb-0 phone-settings-label",
                                                    },
                                                    [_vm._v("Call Forwarding")]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "b-col",
                                                {
                                                  staticClass: "mr-2",
                                                  attrs: { cols: "auto" },
                                                },
                                                [
                                                  _c(
                                                    "b-button",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "b-tooltip",
                                                          rawName:
                                                            "v-b-tooltip.click",
                                                          modifiers: {
                                                            click: true,
                                                          },
                                                        },
                                                      ],
                                                      staticClass: "p-0",
                                                      attrs: {
                                                        title:
                                                          "Call forwarding redirects calls to the number you specify. Voicemail for these calls will be managed by the voicemail system of the forwarded number.",
                                                        variant: "link",
                                                      },
                                                    },
                                                    [
                                                      _c("b-icon", {
                                                        attrs: {
                                                          icon: "question-circle-fill",
                                                          variant: "dark",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "b-col",
                                                { attrs: { cols: "auto" } },
                                                [
                                                  _c("b-form-checkbox", {
                                                    staticClass:
                                                      "align-checkbox-middle",
                                                    attrs: {
                                                      name: "call-forwarding-checkbox",
                                                      size: "lg",
                                                      switch: "",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.callForwardingEnabled,
                                                      callback: function ($$v) {
                                                        _vm.callForwardingEnabled =
                                                          $$v
                                                      },
                                                      expression:
                                                        "callForwardingEnabled",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c("vue-phone-number-input", {
                                            staticClass:
                                              "form-control p-0 phone-settings-call-forwarding-number-input",
                                            attrs: {
                                              "only-countries": ["US"],
                                              "default-country-code": "US",
                                              required: true,
                                              clearable: true,
                                              disabled:
                                                !_vm.callForwardingEnabled,
                                              size: "lg",
                                              "no-example": true,
                                            },
                                            on: {
                                              update: _vm.validateNumberOnEvent,
                                            },
                                            model: {
                                              value: _vm.forwardToNumber,
                                              callback: function ($$v) {
                                                _vm.forwardToNumber = $$v
                                              },
                                              expression: "forwardToNumber",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    { attrs: { cols: "12", md: "6" } },
                                    [
                                      _c(
                                        "b-form-group",
                                        [
                                          _c(
                                            "b-row",
                                            {
                                              staticClass:
                                                "justify-content-start align-items-center mb-1",
                                              attrs: { "no-gutters": "" },
                                            },
                                            [
                                              _c(
                                                "b-col",
                                                {
                                                  staticClass:
                                                    "mr-2 text-nowrap",
                                                  attrs: { cols: "auto" },
                                                },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "mb-0 phone-settings-label",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Attachment Retention Period"
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "b-col",
                                                { attrs: { cols: "auto" } },
                                                [
                                                  _c(
                                                    "b-button",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "b-tooltip",
                                                          rawName:
                                                            "v-b-tooltip.click",
                                                          modifiers: {
                                                            click: true,
                                                          },
                                                        },
                                                      ],
                                                      staticClass: "p-0",
                                                      attrs: {
                                                        title:
                                                          "Attachment retention period is the time period that mms attachments are available.",
                                                        variant: "link",
                                                      },
                                                    },
                                                    [
                                                      _c("b-icon", {
                                                        attrs: {
                                                          icon: "question-circle-fill",
                                                          variant: "dark",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c("b-form-select", {
                                            attrs: {
                                              options:
                                                _vm.messageAttachmentRetentionOptions,
                                            },
                                            model: {
                                              value:
                                                _vm.messageAttachmentRetentionPeriod,
                                              callback: function ($$v) {
                                                _vm.messageAttachmentRetentionPeriod =
                                                  $$v
                                              },
                                              expression:
                                                "messageAttachmentRetentionPeriod",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    [
                                      _c(
                                        "label",
                                        { staticClass: "phone-settings-label" },
                                        [_vm._v("Blocked Numbers")]
                                      ),
                                      _vm.blockedNumbers.length > 0 &&
                                      _vm.mobileView
                                        ? _c(
                                            "b-list-group",
                                            {
                                              staticClass:
                                                "blocked-numbers-list-group",
                                            },
                                            _vm._l(
                                              _vm.blockedNumbers,
                                              function (number) {
                                                return _c(
                                                  "div",
                                                  { key: number },
                                                  [
                                                    _c(
                                                      "div",
                                                      [
                                                        _c(
                                                          "virtual-phone-blocked-number-mobile-view",
                                                          {
                                                            attrs: {
                                                              number: number,
                                                            },
                                                          }
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          )
                                        : _vm.blockedNumbers.length > 0 &&
                                          !_vm.mobileView
                                        ? _c(
                                            "b-row",
                                            { staticClass: "mt-3" },
                                            [
                                              _c("b-col", [
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "show-blocked-numbers-text",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.openBlockedNumbersModal()
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "See all blocked numbers"
                                                    ),
                                                  ]
                                                ),
                                              ]),
                                            ],
                                            1
                                          )
                                        : _c("div", [
                                            _c("p", [
                                              _vm._v(
                                                "No blocked numbers found."
                                              ),
                                            ]),
                                          ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-card",
                        {
                          staticClass: "phone-settings-card",
                          attrs: {
                            "header-class": "phone-setting-card-header",
                            "body-class": "phone-settings-card-body",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "header",
                              fn: function () {
                                return [
                                  _c(
                                    "h4",
                                    {
                                      staticClass:
                                        "mt-0 mb-0 phone-settings-header-text",
                                    },
                                    [_vm._v("Caller ID")]
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ]),
                        },
                        [
                          _c(
                            "b-card-body",
                            [
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    [
                                      _c(
                                        "b-form-group",
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass:
                                                "phone-settings-label",
                                            },
                                            [_vm._v("Name")]
                                          ),
                                          _c("b-form-input", {
                                            attrs: {
                                              state: _vm.validCallerIdName,
                                            },
                                            on: {
                                              blur: _vm.saveCallerIdName,
                                              change: _vm.setUserUpdateCallerId,
                                            },
                                            model: {
                                              value: _vm.callerIdName,
                                              callback: function ($$v) {
                                                _vm.callerIdName = $$v
                                              },
                                              expression: "callerIdName",
                                            },
                                          }),
                                          _c(
                                            "b-form-invalid-feedback",
                                            {
                                              attrs: {
                                                id: "input-live-feedback",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                '\n                    "Caller Id Name can be up 15 characters long, start and end with a letter or number, and can only include letters, numbers, spaces, hyphens, underscores, periods, or apostrophes."\n                  '
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-card",
                        {
                          staticClass: "phone-settings-card",
                          attrs: {
                            "header-class": "phone-setting-card-header",
                            "body-class": "phone-settings-card-body",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "header",
                              fn: function () {
                                return [
                                  _c(
                                    "h4",
                                    {
                                      staticClass:
                                        "mt-0 mb-0 phone-settings-header-text",
                                    },
                                    [_vm._v("Voicemail")]
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ]),
                        },
                        [
                          _c(
                            "b-card-body",
                            [
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    { attrs: { cols: "12", md: "6" } },
                                    [
                                      _c(
                                        "b-form-group",
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass:
                                                "phone-settings-label",
                                            },
                                            [_vm._v("Greeting Type")]
                                          ),
                                          _c("b-form-select", {
                                            attrs: {
                                              options: _vm.greetingTypeOptions,
                                            },
                                            model: {
                                              value: _vm.greetingType,
                                              callback: function ($$v) {
                                                _vm.greetingType = $$v
                                              },
                                              expression: "greetingType",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm.greetingType == "custom"
                                        ? _c(
                                            "b-button",
                                            {
                                              staticClass:
                                                "w-100 mb-2 confirm-button confirm-button-light",
                                              on: { click: _vm.showRecorder },
                                            },
                                            [_vm._v("Manage Custom Greeting")]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    { attrs: { cols: "12", md: "6" } },
                                    [
                                      _c(
                                        "b-form-group",
                                        [
                                          _c(
                                            "b-row",
                                            {
                                              staticClass:
                                                "justify-content-start align-items-center mb-1",
                                              attrs: { "no-gutters": "" },
                                            },
                                            [
                                              _c(
                                                "b-col",
                                                {
                                                  staticClass:
                                                    "mr-2 text-nowrap",
                                                  attrs: { cols: "auto" },
                                                },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "mb-0 phone-settings-label",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Voicemail Retention Period"
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "b-col",
                                                { attrs: { cols: "auto" } },
                                                [
                                                  _c(
                                                    "b-button",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "b-tooltip",
                                                          rawName:
                                                            "v-b-tooltip.click",
                                                          modifiers: {
                                                            click: true,
                                                          },
                                                        },
                                                      ],
                                                      staticClass: "p-0",
                                                      attrs: {
                                                        title:
                                                          "Setting a shorter retention period will delete voicemails older than the newly selected period, regardless of their prior retention period.",
                                                        variant: "link",
                                                      },
                                                    },
                                                    [
                                                      _c("b-icon", {
                                                        attrs: {
                                                          icon: "question-circle-fill",
                                                          variant: "dark",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c("b-form-select", {
                                            ref: "voicemailSelect",
                                            attrs: {
                                              value:
                                                _vm.voicemailRetentionPeriod,
                                              options:
                                                _vm.voicemailRetentionPeriodOptions,
                                            },
                                            on: {
                                              change:
                                                _vm.toggleVoicemailRetentionConfirmation,
                                            },
                                          }),
                                          _vm.voicemailRetentionChangeInProgress
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "voicemail-retention-warning mt-4",
                                                },
                                                [
                                                  _c(
                                                    "b-row",
                                                    [
                                                      _c(
                                                        "b-col",
                                                        {
                                                          attrs: {
                                                            cols: "2",
                                                            "align-self":
                                                              "center",
                                                          },
                                                        },
                                                        [
                                                          _c("b-icon", {
                                                            staticClass: "h3",
                                                            attrs: {
                                                              icon: "exclamation-triangle",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _c("b-col", [
                                                        _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "voicemail-retention-warning-text",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Are you sure that you want to proceed? This action may delete voicemails older than the selected period."
                                                            ),
                                                          ]
                                                        ),
                                                      ]),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "b-row",
                                                    [
                                                      _c("b-col", {
                                                        attrs: { md: "2" },
                                                      }),
                                                      _c(
                                                        "b-col",
                                                        [
                                                          _c(
                                                            "b-button",
                                                            {
                                                              staticClass:
                                                                "cancel-button",
                                                              on: {
                                                                click:
                                                                  _vm.cancelVoicemailRetentionChange,
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                          Cancel\n                        "
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "b-col",
                                                        [
                                                          _c(
                                                            "b-button",
                                                            {
                                                              staticClass:
                                                                "confirm-button-light confirm-button",
                                                              on: {
                                                                click:
                                                                  _vm.confirmVoicemailRetentionChange,
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                          Confirm\n                        "
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    {
                      class: { "mt-3": _vm.mobileView },
                      attrs: { cols: "12", lg: "4" },
                    },
                    [
                      _c("virtual-phone-mobile-settings-card", {
                        attrs: { "mobile-view": _vm.mobileView },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
      _c("virtual-phone-blocked-numbers-modal", {
        attrs: { "blocked-numbers": _vm.blockedNumbers },
      }),
      _c("virtual-phone-voicemail-recorder-modal", {
        ref: "virtual-phone-voicemail-recorder-modal",
        attrs: {
          "custom-voicemail-url": _vm.customVoicemailUrl,
          "upload-url": _vm.uploadUrl,
        },
        on: {
          success: _vm.successfulVoicemailGreetingUpload,
          failure: _vm.failedVoicemailGreetingUpload,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }